// extracted by mini-css-extract-plugin
export var line = "footer-module--line--9C-ep";
export var logo = "footer-module--logo--Yt86o";
export var links = "footer-module--links--LTQh5";
export var contact = "footer-module--contact--B5d1F";
export var map = "footer-module--map--2Hmro";
export var phone = "footer-module--phone--7kKQW";
export var metro = "footer-module--metro--8+7-7";
export var mail = "footer-module--mail--+s7MG";
export var slogan = "footer-module--slogan--YaayD";
export var insurance = "footer-module--insurance--k4P5B";
export var container = "footer-module--container--5GliC";
export var item = "footer-module--item--zTjIK";
export var partners = "footer-module--partners--xaOpF";
export var politic = "footer-module--politic--DCUpn";