import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Logo from "../components/svg/logo";
import { IoMenuSharp, IoSearchSharp } from "react-icons/io5";
import {
  item,
  container,
  searchBtn,
  fullNav,
  nav,
  leftNav,
  logo,
  centralNav,
  rightNav,
  icon,
  contact,
  person,
  user,
  search,
  phone,
  map,
  navbarToggle,
  burger,
} from "./header.module.scss";

function Header() {
  const [openNav, setOpenNav] = useState(false);

  return (
    <header>
      <nav className={nav}>
        <div className={navbarToggle}>
          <button className={burger} onClick={() => setOpenNav(!openNav)}>
            <IoMenuSharp />
          </button>
        </div>
        <div className={leftNav}>
          <button
            className={logo}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <Logo />
          </button>
        </div>
        <div className={centralNav}>
          <a
            onClick={() =>
              window.scrollTo({
                top:
                  document.getElementById("houses").getBoundingClientRect()
                    .top +
                  window.pageYOffset -
                  120,
                behavior: "smooth",
              })
            }
          >
            Наш подход
          </a>
          <a
            onClick={() =>
              window.scrollTo({
                top:
                  document.getElementById("finance").getBoundingClientRect()
                    .top +
                  window.pageYOffset -
                  40,
                behavior: "smooth",
              })
            }
          >
            Сопровождение
          </a>
          <a
            onClick={() =>
              window.scrollTo({
                top:
                  document.getElementById("footer").getBoundingClientRect()
                    .top +
                  window.pageYOffset -
                  40,
                behavior: "smooth",
              })
            }
          >
            Контакты
          </a>
        </div>
        <div className={rightNav}>
          <div className={contact}>
            <a href="tel:+74959899835" className={phone}>
              <i className={icon} />
              <span>+7 (495) 989-98-35</span>
            </a>
            <a href="https://lazovskiy.ru/contacts/" className={map}>
              <i className={icon} />
              <span>Проспект Мира</span>
            </a>
          </div>
          <div className={person}>
            <a href="https://lazovskiy.ru/auth/" className={user}>
              <i className={icon} />
            </a>
            <a href="https://lazovskiy.ru/auth/" className={search}>
              <i className={icon} />
            </a>
          </div>
        </div>
      </nav>

      <AnimatePresence>
        {openNav && (
          <motion.nav
            className={fullNav}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className={item}>
              <a href="https://lazovskiy.ru/search/" className={searchBtn}>
                <p>Поиск</p>
                <div className={container}>
                  <button>
                    <IoSearchSharp />
                  </button>
                </div>
              </a>
            </div>
            <div className={item}>
              <a
                onClick={() => {
                  window.scrollTo({
                    top:
                      document.getElementById("houses").getBoundingClientRect()
                        .top +
                      window.pageYOffset -
                      100,
                    behavior: "smooth",
                  });
                  setOpenNav(false);
                }}
              >
                Наш подход
              </a>
            </div>
            <div className={item}>
              <a
                onClick={() => {
                  window.scrollTo({
                    top:
                      document.getElementById("finance").getBoundingClientRect()
                        .top +
                      window.pageYOffset -
                      10,
                    behavior: "smooth",
                  });
                  setOpenNav(false);
                }}
              >
                Сопровождение
              </a>
            </div>
            <div className={item}>
              <a
                onClick={() => {
                  window.scrollTo({
                    top:
                      document.getElementById("footer").getBoundingClientRect()
                        .top +
                      window.pageYOffset -
                      40,
                    behavior: "smooth",
                  });
                  setOpenNav(false);
                }}
              >
                Контакты
              </a>
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </header>
  );
}

export default Header;
