import * as React from "react";
import Header from "./src/components/header";
import Footer from "./src/components/footer";
import "./src/style/main.scss";

export const wrapRootElement = ({ element }) => (
  <>
    <Header />
    <main>{element}</main>
    <Footer />
  </>
);
