import React from 'react'

function Logo() {
  return (
    <svg
      width="227"
      height="88"
      viewBox="0 0 227 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M79.2736 71.7574L0 71.7379L42.7954 0L122.059 0.0488212L79.2736 71.7574Z" fill="white" />
      <path d="M120.432 0.663975L131.777 30.5816H133.608L122.059 0.0488281L120.432 0.663975Z" fill="white" />
      <path d="M126.034 53.4225V49.5656H124.493V40.3579H117.43V41.8518C117.43 43.834 117.295 45.4158 117.033 46.568C116.771 47.7202 116.364 48.7161 115.802 49.5656H114.785V53.4225H116.374V51.1181H124.455V53.4225H126.034ZM122.817 49.5656H117.634C118.167 48.6868 118.535 47.642 118.748 46.4313C118.961 45.2205 119.068 43.707 119.068 41.9104H122.817V49.5656Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M133.201 51.3102C134.771 51.3102 136.069 50.7732 137.106 49.7088C138.142 48.6348 138.665 47.3166 138.665 45.7348C138.665 44.153 138.142 42.8348 137.106 41.7705C136.069 40.6965 134.771 40.1594 133.201 40.1594C131.631 40.1594 130.333 40.6965 129.296 41.7705C128.279 42.8348 127.756 44.153 127.756 45.7348C127.756 47.3166 128.279 48.6348 129.296 49.7088C130.333 50.7732 131.631 51.3102 133.201 51.3102ZM130.546 42.8934C131.263 42.122 132.145 41.7412 133.201 41.7412C134.257 41.7412 135.139 42.122 135.856 42.8934C136.573 43.6453 136.941 44.6022 136.941 45.7348C136.941 46.8675 136.573 47.8244 135.856 48.5957C135.139 49.3476 134.257 49.7284 133.201 49.7284C132.145 49.7284 131.263 49.3476 130.546 48.5957C129.829 47.8244 129.48 46.8772 129.48 45.7348C129.48 44.5924 129.829 43.6453 130.546 42.8934Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M149.838 51.1154H151.514V40.365H149.838L146.408 45.1983L142.949 40.365H141.234V51.1154H142.911V42.9428L145.885 46.9949H146.864L149.838 42.9428V51.1154Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M117.205 64.5407C117.157 66.5033 117.069 67.7043 116.779 68.6905C116.517 69.6767 116.033 70.1063 115.287 70.1063C115.054 70.1063 114.87 70.0868 114.725 70.0478V71.5807C114.88 71.6296 115.093 71.6589 115.383 71.6589C116.711 71.6589 117.583 71.1218 118.087 69.8915C118.59 68.6515 118.784 67.1283 118.833 64.7165L118.881 62.4121H122.689V71.61H124.365V60.8499H117.263L117.205 64.5407Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M132.601 60.7915H130.983L126.312 71.6103H128.095L129.239 68.9349H134.296L135.459 71.6103H137.261L132.601 60.7915ZM129.83 67.4409L131.777 62.9201L133.724 67.4409H129.83Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M144.575 65.8005C144.982 65.6638 145.37 65.4002 145.718 65.0194C146.067 64.6386 146.232 64.1601 146.232 63.5743C146.232 62.6955 145.883 61.9925 145.166 61.4555C144.449 60.9184 143.519 60.6548 142.356 60.6548C141.136 60.6548 140.012 60.9282 138.975 61.4652V63.0177C140.031 62.4905 141.126 62.2366 142.269 62.2366C143.674 62.2366 144.575 62.7443 144.575 63.6622C144.575 64.5702 143.829 65.1073 142.531 65.1073H141.01V66.6598H142.337C143.907 66.6598 144.943 67.3335 144.943 68.3295C144.943 69.4817 143.829 70.2237 142.017 70.2237C140.845 70.2237 139.459 69.8234 138.529 69.2278V70.8877C139.518 71.5029 140.758 71.8055 142.269 71.8055C143.626 71.8055 144.691 71.5029 145.486 70.8682C146.28 70.2335 146.678 69.4426 146.678 68.4759C146.678 67.7827 146.464 67.1968 146.057 66.7574C145.621 66.2888 145.137 65.9665 144.575 65.8005Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M148.51 66.2302C148.51 67.812 149.033 69.1302 150.05 70.214C151.087 71.2783 152.385 71.8153 153.955 71.8153C155.524 71.8153 156.823 71.2783 157.859 70.214C158.896 69.1399 159.419 67.8217 159.419 66.2302C159.419 64.6386 158.896 63.3302 157.859 62.2659C156.823 61.1918 155.524 60.6548 153.955 60.6548C152.385 60.6548 151.087 61.1918 150.05 62.2659C149.033 63.3204 148.51 64.6484 148.51 66.2302ZM151.3 63.3888C152.017 62.6174 152.899 62.2366 153.955 62.2366C155.011 62.2366 155.893 62.6174 156.61 63.3888C157.327 64.1406 157.695 65.0975 157.695 66.2302C157.695 67.3628 157.327 68.3197 156.61 69.0911C155.893 69.8429 155.011 70.2237 153.955 70.2237C152.899 70.2237 152.017 69.8429 151.3 69.0911C150.583 68.3197 150.234 67.3726 150.234 66.2302C150.234 65.0878 150.583 64.1406 151.3 63.3888Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M170.832 68.5739C170.832 66.992 169.854 65.9473 168.565 65.7032C169.369 65.4005 169.98 64.5998 169.98 63.4867C169.98 61.8268 168.749 60.8406 166.84 60.8406H161.996V71.591H167.238C168.352 71.591 169.214 71.3176 169.863 70.761C170.513 70.224 170.832 69.4819 170.832 68.5739ZM163.663 62.4029H166.54C167.654 62.4029 168.313 62.8813 168.313 63.7894C168.313 64.6974 167.654 65.1857 166.54 65.1857H163.663V62.4029ZM166.928 70.0482H163.663V66.6698H166.928C168.255 66.6698 169.011 67.2654 169.011 68.3297C169.02 69.4233 168.226 70.0482 166.928 70.0482Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M175.532 69.1009C174.786 68.349 174.399 67.3824 174.399 66.2302C174.399 65.078 174.767 64.1211 175.513 63.3692C176.259 62.6174 177.208 62.2366 178.322 62.2366C179.379 62.2366 180.338 62.51 181.2 63.0861V61.3773C180.357 60.8989 179.398 60.6548 178.303 60.6548C176.685 60.6548 175.348 61.1821 174.282 62.2366C173.216 63.2814 172.684 64.6191 172.684 66.2302C172.684 67.8413 173.216 69.179 174.282 70.2433C175.367 71.288 176.704 71.8153 178.303 71.8153C179.505 71.8153 180.541 71.5224 181.433 70.9561V69.2473C180.609 69.9113 179.592 70.2335 178.381 70.2335C177.237 70.2237 176.288 69.8527 175.532 69.1009Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M185.473 66.4448L190.501 71.6003H192.788L187.062 65.7613L191.994 60.8499H189.843L185.473 65.2438V60.8499H183.797V71.6003H185.473V66.4448Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M198.599 60.6453C197.03 60.6453 195.732 61.1823 194.695 62.2564C193.677 63.3207 193.154 64.6388 193.154 66.2207C193.154 67.8025 193.677 69.1206 194.695 70.2045C195.732 71.2688 197.03 71.8058 198.599 71.8058C200.169 71.8058 201.467 71.2688 202.504 70.2045C203.541 69.1304 204.064 67.8122 204.064 66.2207C204.064 64.6291 203.541 63.3207 202.504 62.2564C201.467 61.1921 200.169 60.6453 198.599 60.6453ZM201.254 69.0816C200.537 69.8334 199.655 70.2142 198.599 70.2142C197.543 70.2142 196.662 69.8334 195.945 69.0816C195.228 68.3102 194.879 67.3631 194.879 66.2207C194.879 65.0782 195.228 64.1311 195.945 63.3793C196.662 62.6079 197.543 62.2271 198.599 62.2271C199.655 62.2271 200.537 62.6079 201.254 63.3793C201.971 64.1311 202.339 65.088 202.339 66.2207C202.339 67.3533 201.971 68.32 201.254 69.0816Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M208.309 62.4024H214.103V60.8499H206.633V71.6003H208.309V62.4024Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M226.001 66.2302C226.001 64.6484 225.478 63.3302 224.441 62.2659C223.405 61.1918 222.106 60.6548 220.537 60.6548C218.967 60.6548 217.669 61.1918 216.632 62.2659C215.615 63.3302 215.092 64.6484 215.092 66.2302C215.092 67.812 215.615 69.1302 216.632 70.214C217.669 71.2783 218.967 71.8153 220.537 71.8153C222.106 71.8153 223.405 71.2783 224.441 70.214C225.478 69.1302 226.001 67.812 226.001 66.2302ZM223.192 69.0813C222.475 69.8332 221.593 70.214 220.537 70.214C219.481 70.214 218.599 69.8332 217.882 69.0813C217.165 68.31 216.816 67.3628 216.816 66.2204C216.816 65.078 217.165 64.1309 217.882 63.379C218.599 62.6076 219.481 62.2268 220.537 62.2268C221.593 62.2268 222.475 62.6076 223.192 63.379C223.909 64.1309 224.277 65.0878 224.277 66.2204C224.277 67.3531 223.909 68.3197 223.192 69.0813Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M116.401 86.0714C116.043 85.7102 115.859 85.2415 115.859 84.6849C115.859 84.1284 116.033 83.6694 116.391 83.3082C116.75 82.9469 117.205 82.7614 117.738 82.7614C118.242 82.7614 118.707 82.8981 119.124 83.1715V82.3513C118.717 82.1169 118.261 81.9998 117.729 81.9998C116.953 81.9998 116.304 82.2536 115.791 82.7614C115.277 83.2691 115.016 83.9038 115.016 84.6849C115.016 85.4661 115.277 86.1105 115.791 86.6182C116.314 87.126 116.963 87.3701 117.729 87.3701C118.31 87.3701 118.804 87.2334 119.23 86.96V86.1398C118.833 86.462 118.339 86.6085 117.758 86.6085C117.215 86.6085 116.76 86.4327 116.401 86.0714Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M119.986 82.8395H121.682V87.2725H122.486V82.8395H124.191V82.0974H119.986V82.8395Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M127.428 82.0974H125.219V87.2725H126.023V85.1341H127.428C127.961 85.1341 128.377 84.9974 128.687 84.7045C128.997 84.4115 129.152 84.0503 129.152 83.5913C129.152 83.152 128.997 82.7809 128.687 82.5075C128.387 82.2341 127.97 82.0974 127.428 82.0974ZM127.37 84.392H126.033V82.8493H127.37C127.98 82.8493 128.338 83.1227 128.338 83.6206C128.338 84.1186 127.97 84.392 127.37 84.392Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M132.669 81.9998C131.913 81.9998 131.283 82.2634 130.789 82.7809C130.295 83.2886 130.043 83.9233 130.043 84.6947C130.043 85.4563 130.295 86.091 130.789 86.6085C131.283 87.1162 131.913 87.3798 132.669 87.3798C133.424 87.3798 134.044 87.1162 134.548 86.6085C135.042 86.091 135.294 85.4563 135.294 84.6947C135.294 83.9331 135.042 83.2984 134.548 82.7809C134.044 82.2634 133.424 81.9998 132.669 81.9998ZM133.948 86.0617C133.599 86.423 133.172 86.6085 132.669 86.6085C132.165 86.6085 131.739 86.423 131.39 86.0617C131.041 85.6906 130.876 85.2317 130.876 84.6849C130.876 84.1381 131.051 83.6792 131.39 83.3179C131.739 82.9469 132.165 82.7614 132.669 82.7614C133.172 82.7614 133.599 82.9469 133.948 83.3179C134.296 83.6792 134.471 84.1381 134.471 84.6849C134.471 85.2317 134.287 85.6906 133.948 86.0617Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M137.347 85.8957V82.0974H136.543V87.2725H137.231L140.07 83.4546V87.2725H140.884V82.0974H140.176L137.347 85.8957Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M141.912 82.8395H143.608V87.2725H144.412V82.8395H146.117V82.0974H141.912V82.8395Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M147.956 84.89H150.272V84.1381H147.956V82.8395H150.805V82.0974H147.143V87.2725H150.902V86.5304H147.956V84.89Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M152.637 83.874C152.618 84.8212 152.569 85.3972 152.434 85.8757C152.308 86.3444 152.075 86.5592 151.717 86.5592C151.61 86.5592 151.523 86.5494 151.445 86.5299V87.272C151.523 87.2915 151.62 87.311 151.765 87.311C152.405 87.311 152.821 87.0474 153.063 86.4616C153.306 85.8659 153.402 85.1336 153.422 83.9717L153.441 82.8585H155.272V87.2915H156.077V82.1165H152.656L152.637 83.874Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M159.641 83.8843H158.314V82.0974H157.51V87.2725H159.651C160.242 87.2725 160.707 87.1162 161.056 86.8038C161.405 86.4816 161.579 86.0812 161.579 85.5833C161.579 85.0755 161.414 84.6752 161.066 84.353C160.707 84.0405 160.232 83.8843 159.641 83.8843ZM159.593 86.5304H158.304V84.6361H159.593C160.31 84.6361 160.736 84.9876 160.736 85.593C160.746 86.1789 160.329 86.5304 159.593 86.5304Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M166.134 84.1381H163.46V82.0974H162.646V87.2725H163.46V84.89H166.134V87.2725H166.948V82.0974H166.134V84.1381Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M170.144 82.0676L167.896 87.272H168.759L169.311 85.9831H171.753L172.315 87.272H173.177L170.929 82.0676H170.144ZM169.582 85.2703L170.522 83.0929L171.462 85.2703H169.582Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M174.117 83.5718C174.117 84.2846 174.534 84.8314 175.26 84.9974L173.623 87.2725H174.602L176.152 85.0853H177.353V87.2725H178.157V82.0974H175.813C174.766 82.0974 174.117 82.6833 174.117 83.5718ZM177.353 82.8395V84.3627H175.852C175.27 84.3627 174.921 84.0796 174.921 83.6011C174.921 83.1227 175.27 82.8395 175.852 82.8395H177.353Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M185.598 82.0974H184.561L182.449 84.2163V82.0974H181.645V87.2725H182.449V84.7923L184.871 87.2725H185.976L183.214 84.4604L185.598 82.0974Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M188.776 81.9998C188.02 81.9998 187.4 82.2634 186.896 82.7809C186.402 83.2886 186.15 83.9233 186.15 84.6947C186.15 85.4563 186.402 86.091 186.896 86.6085C187.4 87.1162 188.02 87.3798 188.776 87.3798C189.532 87.3798 190.162 87.1162 190.656 86.6085C191.159 86.091 191.402 85.4563 191.402 84.6947C191.402 83.9331 191.15 83.2984 190.656 82.7809C190.162 82.2634 189.532 81.9998 188.776 81.9998ZM190.055 86.0617C189.706 86.423 189.28 86.6085 188.776 86.6085C188.272 86.6085 187.846 86.423 187.497 86.0617C187.148 85.6906 186.984 85.2317 186.984 84.6849C186.984 84.1381 187.148 83.6792 187.497 83.3179C187.846 82.9469 188.263 82.7614 188.776 82.7614C189.29 82.7614 189.706 82.9469 190.055 83.3179C190.404 83.6792 190.578 84.1381 190.578 84.6849C190.578 85.2317 190.404 85.6906 190.055 86.0617Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M195.142 84.4311L193.476 82.0974H192.652V87.2725H193.457V83.3375L194.89 85.2903H195.365L196.789 83.3375V87.2725H197.603V82.0974H196.789L195.142 84.4311Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M199.027 87.2725H199.832V82.8395H202.399V87.2725H203.203V82.0974H199.027V87.2725Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M206.4 82.0676L204.152 87.272H205.015L205.567 85.9831H208.008L208.57 87.272H209.433L207.185 82.0676H206.4ZM205.848 85.2703L206.788 83.0929L207.728 85.2703H205.848Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M213.861 84.1381H211.187V82.0974H210.373V87.2725H211.187V84.89H213.861V87.2725H214.675V82.0974H213.861V84.1381Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M216.902 85.8957V82.0974H216.098V87.2725H216.786L219.634 83.4546V87.2725H220.438V82.0974H219.731L216.902 85.8957Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
      <path d="M221.932 83.5718C221.932 84.2846 222.348 84.8314 223.075 84.9974L221.438 87.2725H222.416L223.966 85.0853H225.168V87.2725H225.972V82.0974H223.627C222.581 82.0974 221.932 82.6833 221.932 83.5718ZM225.168 82.8395V84.3627H223.666C223.085 84.3627 222.736 84.0796 222.736 83.6011C222.736 83.1227 223.085 82.8395 223.666 82.8395H225.168Z" fill="white" stroke="white" strokeWidth="0.25" strokeMiterlimit="10" />
    </svg>
  )
}

export default Logo